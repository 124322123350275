import styled, { ThemeProvider } from "styled-components";

import HeaderBanner from "./components/header/header-banner";
import About from "./components/sections/about";
import Team from "./components/sections/team";
import Contact from "./components/sections/contact";

import { GlobalStyles } from "./theme/GlobalStyles";
import { useTheme } from "./theme/useTheme";

import circuitIcon from "./images/icons/circuit-board.svg";
import circuitIconWhite from "./images/icons/circuit-board-white.svg";

function App() {
  const { theme, themeLoaded, setMode, darkMode } = useTheme();
  return (
    <>
      {themeLoaded && (
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <div className="App">
            <HeaderBanner darkMode={darkMode} setMode={setMode} />
            <Introduction
              circuitIcon={circuitIcon}
              circuitIconWhite={circuitIconWhite}
              darkMode={darkMode}
            >
              <h2>CONSULTING ENGINEERS</h2>
              <div>
                <p>
                   EAMEKH Consulting Engineers provides a wide range of
                   consulting services, including: Systems Engineering, Software
                   Safety, Front-end and Back-end Web development, and Finite Element
                   analysis of mechanical systems.
                </p>
              </div>
              <About />
              <Team />
              <Contact darkMode={darkMode}/>
              
            </Introduction>
            <Footer>Copyright © EAMEKH Consulting Engineers. All Rights Reserved.</Footer>
          </div>
        </ThemeProvider>
      )}
    </>
  );
}

export default App;

const Introduction = styled.div`
  padding-bottom: 1em;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url(${({ darkMode, circuitIcon, circuitIconWhite }) =>  darkMode ? circuitIconWhite : circuitIcon});
  background-repeat: repeat;
  background-position: 50% 50%;
  & > *:not(.full-width-section) {
    padding-left: calc(4vw + 15px);
    padding-right: calc(4vw + 15px);
  }
`;

const Footer = styled.div`
  margin-bottom:10px;
  display:flex;
  justify-content:center;
`;

import React from "react";
import styled from "styled-components";

const RocketIcon = () => {
  return (
    <StyledSvg
      width="128"
      height="128"
      viewBox="0 0 1150 1150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1150" height="1150" rx="200" className="sr1" />
      <path
        d="M543.799 604.6C526.599 626.1 507.5 649.7 494.1 666.2C479.2 621.4 499.099 558.8 521.299 512C526.7 541.1 534.099 571.9 543.799 604.6Z"
        fill="#F2C94C"
      />
      <path
        d="M648.6 438.8C670.7 460 690.5 499.6 675.6 561.5C662.2 560.4 643.2 558.9 626 557.3C635.8 513.3 643.2 474 648.6 438.8Z"
        fill="#F2994A"
      />
      <path
        opacity="0.2"
        d="M601.1 659.3C724.6 184.7 623 144.4 584.6 128.2C546.2 188.8 444.6 346.4 568.3 678.2L601.1 659.3Z"
        fill="url(#paint0_linearr)"
      />
      <path
        opacity="0.1"
        d="M564.7 632C688.3 157.5 586.7 117.2 548.2 100.9C509.8 161.5 408.2 319.1 531.9 650.9L564.7 632Z"
        fill="url(#paint1_linearr)"
      />
      <path
        opacity="0.15"
        d="M568.3 678.2C444.6 346.4 546.2 188.8 584.6 128.2V668.8L568.3 678.2Z"
        fill="url(#paint2_linearr)"
      />
      <path
        opacity="0.1"
        d="M547.7 295.3C547.7 324.2 564.7 337.8 585.7 325.7C606.7 313.6 623.7 280.3 623.7 251.4C623.7 222.5 606.7 208.9 585.7 221C564.7 233.1 547.7 266.4 547.7 295.3Z"
        className="ssp"
      />
      <path
        opacity="0.1"
        d="M584.9 1003.4C564.3 1048.7 533.6 1087.7 499.3 1107.5C437.3 1143.3 387 1102.9 386.9 1017.3C386.9 931.7 437.2 833.2 499.2 797.4C519.6 785.6 538.7 782.1 555.2 785.7C563.9 771.5 568.1 753.5 571.8 737.7C576.1 719.5 579.7 704 588.7 700.1C599 695.6 603.6 707 610.1 722.8C613.9 732.1 618.4 743.1 625 753.2C632.1 745.7 639.6 739.5 647.5 735C680.6 715.9 708.2 732.4 714.7 771.8C719.2 768.4 723.7 765.3 728.3 762.6C781.4 732 824.4 766.7 824.4 840.3C824.4 913.8 781.4 998.2 728.4 1028.8C676.9 1058.5 634.8 1026.6 632.4 957.5C622.7 975.5 609.8 990.6 595.5 998.8C592 1000.8 588.4 1002.3 584.9 1003.4Z"
        fill="url(#paint3_linearr)"
      />
      <path
        opacity="0.3"
        d="M455.4 458.2L184.6 614.5L184.5 755.6L431.3 613.1L455.2 620.9L455.4 458.2Z"
        fill="url(#paint4_linearr)"
      />
      <path d="M263.4 639.3V601L161.2 660V698.3L263.4 639.3Z" fill="#F2C94C" />
      <path
        opacity="0.1"
        d="M385.7 532.7L326.4 567V579.4L385.7 545.2V532.7Z"
        className="ssp"
      />
      <path
        opacity="0.1"
        d="M385.7 557.5L278.6 619.3V631.7L385.7 569.9V557.5Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M385.7 582.3L247.2 662.3V674.7L385.7 594.7V582.3Z"
        className="ssp"
      />
      <path
        d="M400.3 537.2C400.3 548.8 407.2 554.3 415.7 549.4C424.2 544.5 431.1 531.1 431.1 519.5C431.1 507.9 424.2 502.4 415.7 507.3C407.2 512.2 400.3 525.6 400.3 537.2Z"
        fill="#F2C94C"
      />
      <path
        opacity="0.3"
        d="M688.9 258.9L959.6 102.7L959.5 243.8L712.7 386.2L688.8 421.6L688.9 258.9Z"
        fill="url(#paint5_linearr)"
      />
      <path
        d="M988.4 157.7V119.4L886.2 178.4V216.7L988.4 157.7Z"
        fill="#F2994A"
      />
      <path
        opacity="0.15"
        d="M758.5 253.1L817.8 218.9V231.3L758.5 265.5V253.1Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M758.4 277.9L872.7 211.9V224.3L758.4 290.3V277.9Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M758.4 302.7L896.9 222.7V235.1L758.4 315.1V302.7Z"
        className="ssp"
      />
      <path
        d="M743.8 274.4C743.8 286 736.9 299.4 728.4 304.3C719.9 309.2 713 303.7 713 292.1C713 280.5 719.9 267.1 728.4 262.2C737 257.4 743.9 262.8 743.8 274.4Z"
        fill="#F2994A"
      />
      <path
        opacity="0.2"
        d="M724.9 409.5L886.6 316.2V400.4L724.9 493.7V409.5Z"
        fill="url(#paint6_linearr)"
      />
      <path
        opacity="0.15"
        d="M845.2 360.3L789.8 392.3V402.4L845.2 370.4V360.3Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M845.1 380.6L741.5 440.4V450.6L845.1 390.8V380.6Z"
        className="ssp"
      />
      <path
        d="M853.7 363.3C853.7 370.2 857.8 373.5 862.9 370.6C868 367.7 872.1 359.7 872.1 352.7C872.1 345.8 868 342.5 862.9 345.4C857.8 348.4 853.7 356.4 853.7 363.3Z"
        fill="#F2994A"
      />
      <path
        opacity="0.2"
        d="M244.6 473.1L406.3 379.8V464L244.6 557.4V473.1Z"
        fill="url(#paint7_linearr)"
      />
      <path
        opacity="0.15"
        d="M364.9 424L309.5 456V466.1L364.9 434.1V424Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M364.9 444.3L261.3 504.1V514.3L364.9 454.5V444.3Z"
        className="ssp"
      />
      <path
        d="M373.4 427C373.4 433.9 377.5 437.2 382.6 434.3C387.7 431.4 391.8 423.4 391.8 416.4C391.8 409.5 387.7 406.2 382.6 409.1C377.5 412 373.4 420.1 373.4 427Z"
        fill="#F2C94C"
      />
      <defs>
        <linearGradient
          id="paint0_linearr"
          x1="440.472"
          y1="145.027"
          x2="751.323"
          y2="683.58"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss2" />
          <stop offset="1" className="ss2" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linearr"
          x1="404.08"
          y1="117.734"
          x2="714.931"
          y2="656.288"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss2" />
          <stop offset="1" className="ss2" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linearr"
          x1="498.44"
          y1="262.462"
          x2="738.403"
          y2="695.442"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss2" stopOpacity="0" />
          <stop offset="1" className="ss2" />
        </linearGradient>
        <linearGradient
          id="paint3_linearr"
          x1="521.014"
          y1="784.818"
          x2="655.277"
          y2="1017.43"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss2" />
          <stop offset="1" className="ss2" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linearr"
          x1="339.054"
          y1="462.769"
          x2="304.055"
          y2="746.635"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss2" />
          <stop offset="1" className="ss2" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linearr"
          x1="747.899"
          y1="202.401"
          x2="887.681"
          y2="311.846"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss2" />
          <stop offset="1" className="ss2" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linearr"
          x1="787.497"
          y1="373.327"
          x2="823.962"
          y2="436.503"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss2" />
          <stop offset="1" className="ss2" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linearr"
          x1="307.233"
          y1="437.003"
          x2="343.7"
          y2="500.181"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss2" />
          <stop offset="1" className="ss2" stopOpacity="0" />
        </linearGradient>
      </defs>
    </StyledSvg>
  );
};

export default RocketIcon;

const StyledSvg = styled.svg`
  .sr1 {
    fill: none;
  }

  .ss2 {
    stop-color: ${({ theme }) => theme.colors.text};
  }

  .ssp {
    fill: ${({ theme }) => theme.colors.text};
  }
`;

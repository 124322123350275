import React, { useState, useEffect, useRef } from "react";

import Header from "./header";
import TitleBanner from "./title-banner";
import ScrollToTop from "../reusable-components/scroll-to-top";

const isBrowser = typeof window !== `undefined`;

const HeaderBanner = ({ darkMode, setMode = () => {} }) => {
  
  const bannerImgRef = useRef(null);
  const headerRef = useRef(null);
  const titleRef = useRef(null);

  const [headerHeight, setHeaderHeight] = useState(null);
  const [titleHeight, setTitleHeight] = useState(null);
  const [bannerHeight, setBannerHeight] = useState(null);

  const [headerBlur, setHeaderblur] = useState(false);
  const [titleInHeader, setTitleInHeader] = useState(false);
  const [belowBanner, setBelowBanner] = useState(false);

  useEffect(() => {
    const handleDocumentScroll = () => {
      setHeaderHeight(headerRef?.current?.clientHeight);
      setTitleHeight(titleRef?.current?.clientHeight);
      setBannerHeight(bannerImgRef?.current?.clientHeight);

      const windYOffset = isBrowser && window?.pageYOffset;
      const startEffect =
        window.innerWidth > 1000
          ? bannerHeight / 2 - titleHeight / 2 - headerHeight
          : headerHeight;

      windYOffset > headerHeight ? setHeaderblur(true) : setHeaderblur(false);
      windYOffset > startEffect
        ? setTitleInHeader(true)
        : setTitleInHeader(false);
      windYOffset > bannerHeight - headerHeight
        ? setBelowBanner(true)
        : setBelowBanner(false);
    };

    window.addEventListener("scroll", handleDocumentScroll);
    return () => window.removeEventListener("scroll", handleDocumentScroll);
  }, [
    headerRef,
    titleRef,
    bannerImgRef,
    bannerHeight,
    titleHeight,
    headerHeight,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setHeaderHeight(headerRef?.current?.clientHeight);
      setTitleHeight(titleRef?.current?.clientHeight);
      setBannerHeight(bannerImgRef?.current?.clientHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [headerRef, titleRef, bannerImgRef]);

  return (
    <>
      <Header
        ref={headerRef}
        headerBlur={headerBlur}
        belowBanner={belowBanner}
        darkMode={darkMode}
        setMode={setMode}
      />
      <TitleBanner
        ref={{ titleRef, bannerImgRef }}
        titleInHeader={titleInHeader}
      />
      <ScrollToTop headerBlur={headerBlur} />
    </>
  );
};

export default HeaderBanner;

import { useEffect, useState } from "react";
import { setToLS, getFromLS } from "../utils/storage";

export const useTheme = () => {
  const [themeLoaded, setThemeLoaded] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  const themeColors = {
    black: "#000000",
    black1: "#111016",
    white: "#ffffff",
    greyBrown: "#423e3d",
    blue: "#006DF0",
    yellow: "#c49300",
  };

  const themeBreakPoints = {
    mobile: 900,
  };

  const theme = {
    colors: {
      body: darkMode ? themeColors.black1 : themeColors.white,
      text: darkMode ? themeColors.white : themeColors.black1,
      icons: darkMode ? themeColors.white : themeColors.greyBrown,
      accent: themeColors.greyBrown,
      themeShade: themeColors.black1,
      themeLight: themeColors.white,
      iconColor: themeColors.yellow,
      headerShadow: darkMode ? themeColors.black : `${themeColors.greyBrown}22`,
      button: {
        text: themeColors.white,
        background: themeColors.black1,
      },
      link: {
        text: "teal",
        opacity: 1,
      },
    },
    breakPoints: {
      mobile: themeBreakPoints.mobile,
    },
  };

  const setMode = () => {
    setDarkMode(!darkMode);
    setToLS("mode", !darkMode);
  };

  useEffect(() => {
    const getMode = getFromLS("mode");
    getMode ? setDarkMode(getMode) : setDarkMode(false);
    setThemeLoaded(true);
  }, []);

  return { theme, themeLoaded, setMode, darkMode };
};

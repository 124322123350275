import React from "react";
import styled from "styled-components";
import { Element } from "react-scroll";

import TestIcon from "../../images/icons/test-icon";
import RocketIcon from "../../images/icons/rocket-icon";
import ChartIcon from "../../images/icons/chart-icon";
import PercentIcon from "../../images/icons/percent-icon";
import ShieldIcon from "../../images/icons/shield-icon";
import RulerIcon from "../../images/icons/ruler-icon";

import StyledCard from "../reusable-components/styled-card";

const aboutData = [
  {
    infoTitle: "Safety Engineering",
    infoContent: [
      "RTCA DO-178",
      "RTCA DO-254",
      "MIL-STD-882",
      "Full Safety Life Cycle",
    ],
    Icon: ShieldIcon,
  },
  {
    infoTitle: "Systems Engineering",
    infoContent: [
      "Requirements Management",
      "Systems Engineering Life Cycle",
      "Model Based System Engineering",
      "System Qualification",
    ],
    Icon: RocketIcon,
  },
  {
    infoTitle: "Landward Defence",
    infoContent: [
        "Fire Directing Systems",
        "Laying & Navigation Systems",
        "Target Tracking Systems",
        "Integration of Sight Systems",
        "Turret & Platform Integration",
    ],
    Icon: ShieldIcon,
  },
  {
    infoTitle: "Software Engineering",
    infoContent: [
      "C/C++, Python",
      "Software Design & Development", 
      "Software Integration & Maintenance",
    ],
    Icon: PercentIcon,
  },
  {
    infoTitle: "Structural Engineering",
    infoContent: [
      "Advance Structural",
      "Wave Propagation", 
      "Thermal Analysis (FEA)",
    ],
    Icon: RulerIcon,
  },
  {
    infoTitle: "Computational Mechanics",
    infoContent: [
      "Mathematical Modelling of Dynamic Systems",
      "Motion Analysis",
    ],
    Icon: ChartIcon,
  },
  {
    infoTitle: "Web Development",
    infoContent: [
      "ReactJS, JAMStack & Gatsby",
      "Javascript, CSS & HTML",
      "SEO & Wordpress",
    ],
    Icon: TestIcon,
  },

];

const About = () => {
  return (
    <>
      <h2>
        <Element name="about">About EAMEKH</Element>
      </h2>
      <div>
        <p>
      EAMEKH was founded in 2020 in order to provide value to clients in the
      software safety and systems engineering arenas.  Although EAMEKH is a
      young company, we have decades of experience in delivering systems that
      work.

        </p>
        <p>
	  We are excited to partner with clients to provide solutions for complex systems, including software.
        </p>
      </div>
      <CardContainer>
        {aboutData.map(({ infoTitle, infoContent, Icon }, aboutInfoIndex) => {
          return !(infoTitle && infoContent) ? null : (
            <StyledCard
              className="about"
              key={`about-info-${aboutInfoIndex}`}
              customWidth="31%"
            >
              <CardHeadingStyling>
                <Icon />
                <h3>{infoTitle}</h3>
              </CardHeadingStyling>
              <ul>
                {infoContent.map((infoItem, infoItemIndex) => {
                  return (
                    <li key={`about-info-content${infoItemIndex}`}>
                      {infoItem}
                    </li>
                  );
                })}
              </ul>
            </StyledCard>
          );
        })}
      </CardContainer>
    </>
  );
};

export default About;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
`;

const CardHeadingStyling = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}px) {
    flex-direction: column-reverse;
  }
`;

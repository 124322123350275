import React from "react";
import styled from "styled-components";

const ChartIcon = () => {
  return (
    <StyledSvg
    width="128"
      height="128"
      viewBox="0 0 1150 1150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <rect width="1150" height="1150" rx="200" className="sr1" />
        <path
          opacity="0.3"
          d="M1014.8 665.6C1112.4 580.8 1126.1 661.5 1132.7 662.8C1132.7 662.8 1135.6 682.3 1132.7 753.4L181.3 1302.5V916.1C289.4 722.4 361 587.1 431.5 649.5C553.5 757.6 612.9 830.2 758 680.2C843.9 591.4 897.6 767.4 1014.8 665.6Z"
          fill="url(#paint0_linearc)"
        />
        <path
          opacity="0.3"
          d="M889.1 733.8C986.7 649 1000.4 729.7 1007 731C1007 731 1009.9 750.5 1007 821.6L55.5996 1370.7V984.3C209.6 760.7 311.1 802.3 399.1 834.6C485.3 866.2 516.9 800.2 626 706.1C715.8 628.6 771.9 835.5 889.1 733.8Z"
          fill="url(#paint1_linearc)"
        />
        <path
          d="M367.5 644.6C367.5 636.6 372.2 627.5 377.9 624.2C383.6 620.9 388.3 624.6 388.3 632.6C388.3 640.6 383.6 649.7 377.9 653C372.1 656.3 367.5 652.6 367.5 644.6Z"
          fill="#F2994A"
        />
        <path
          d="M643.3 698.4C643.3 690.7 648.1 681.7 654.1 678.3C660.1 674.9 664.9 678.3 664.9 686C664.9 693.7 660.1 702.7 654.1 706.1C648.2 709.5 643.3 706.1 643.3 698.4Z"
          fill="#F2C94C"
        />
        <path
          opacity="0.07"
          d="M434 132.8L249.4 239.4V602.2L434 495.7V132.8Z"
          className="ssp"
        />
        <path
          opacity="0.3"
          d="M470 164L285.4 270.6V633.4L365.2 587.4L377.7 597.6L390.2 572.9L470 526.9V164Z"
          fill="url(#paint2_linearc)"
        />
        <path
          opacity="0.1"
          d="M309.6 383.7C309.6 395.3 311.3 405.9 314.5 414.8C317.7 423.7 322.4 430.7 328.3 435.5C334.2 440.3 341.3 442.7 349 442.7C356.7 442.7 365.1 440.2 373.4 435.3V346.8L309.6 383.7Z"
          className="ssp"
        />
        <path
          opacity="0.3"
          d="M373.5 435.3C384.7 428.8 395.7 418.4 405.4 405C415.1 391.6 423.2 375.8 428.8 359.1C434.4 342.4 437.4 325.4 437.4 309.9C437.4 294.4 434.4 280.8 428.8 270.6L373.5 346.8V435.3Z"
          className="ssp"
        />
        <path
          d="M309.6 383.7C309.6 364.2 314.2 342.6 322.8 322.2C331.4 301.8 343.4 283.8 356.9 270.9C370.5 258 384.9 251 397.9 251C410.9 251 421.7 257.9 428.8 270.7L373.5 346.9L309.6 383.7Z"
          fill="#F2994A"
        />
        <path
          opacity="0.15"
          d="M445.9 439.2L333.4 504.1V517.7L445.9 452.8V439.2Z"
          className="ssp"
        />
        <path
          opacity="0.15"
          d="M445.9 465.8L309.5 544.5V558.1L446 479.4L445.9 465.8Z"
          className="ssp"
        />
        <path
          opacity="0.15"
          d="M446 492.3L357.6 543.3V556.9L446 505.9V492.3Z"
          className="ssp"
        />
        <path
          opacity="0.07"
          d="M710.9 187L526.3 293.6V656.4L710.9 549.9V187Z"
          className="ssp"
        />
        <path
          opacity="0.3"
          d="M746.5 218.5L561.9 325V687.8L641.6 641.9L654.1 652.1L666.7 627.4L746.5 581.3V218.5Z"
          fill="url(#paint3_linearc)"
        />
        <path
          opacity="0.1"
          d="M586 438.1C586 453.6 589 467.2 594.6 477.4C600.2 487.6 608.3 494.1 618 496.3L649.9 401.2L586 438.1Z"
          className="ssp"
        />
        <path
          opacity="0.3"
          d="M617.9 496.3C630.1 499 644.2 494.7 658.2 484.2C672.1 473.6 685.1 457.3 695 437.8L649.8 401.3L617.9 496.3Z"
          className="ssp"
        />
        <path
          d="M586 438.1C586 423.5 588.6 407.7 593.5 392C598.4 376.3 605.6 361.2 614.3 348.1C623 335 633.1 324.2 643.5 316.7C654 309.2 664.5 305.3 674.2 305.3C683.9 305.3 692.5 309.1 699.1 316.5C705.8 323.9 710.3 334.6 712.4 347.7C714.5 360.8 713.9 375.8 710.9 391.5C707.9 407.2 702.4 423 694.9 437.6L649.7 401.1L586 438.1Z"
          fill="#F2C94C"
        />
        <path
          opacity="0.15"
          d="M722.4 493.6L594.7 567.3V580.9L722.4 507.2V493.6Z"
          className="ssp"
        />
        <path
          opacity="0.15"
          d="M722.4 520.2L589.8 596.8V610.4L722.4 533.8V520.2Z"
          className="ssp"
        />
        <path
          opacity="0.15"
          d="M722.4 546.8L634 597.8V611.4L722.4 560.4V546.8Z"
          className="ssp"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linearc"
          x1="483.829"
          y1="432.428"
          x2="604.985"
          y2="817.383"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linearc"
          x1="431.293"
          y1="598.165"
          x2="500.105"
          y2="909.645"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linearc"
          x1="377.7"
          y1="214.788"
          x2="377.7"
          y2="703.374"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" stopOpacity="0" />
          <stop offset="1" className="ss1" />
        </linearGradient>
        <linearGradient
          id="paint3_linearc"
          x1="654.2"
          y1="274.274"
          x2="654.2"
          y2="736.921"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" stopOpacity="0" />
          <stop offset="1" className="ss1" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="1150" height="1150" className="ssp" />
        </clipPath>
      </defs>
    </StyledSvg>
  );
};

export default ChartIcon;

const StyledSvg = styled.svg`
  .sr1 {
    fill: none;
  }

  .ss1 {
    stop-color: ${({ theme }) => theme.colors.text};
  }

  .ssp {
    fill: ${({ theme }) => theme.colors.text};
  }
`;

import React from "react";
import styled from "styled-components";

const TestIcon = () => {
  return (
    <StyledSvg
      width="128"
      height="128"
      viewBox="0 0 1150 1150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1150" height="1150" rx="200" fill="none" />
      <path
        opacity="0.07"
        d="M210 624.5C222.7 617.1 231.9 616.9 237.6 623.9C243.3 630.8 246.1 642.5 246.1 659V747.9C246.1 764.8 247.7 778.8 250.8 789.9C253.9 801.4 259.2 809.6 266.5 814.4C273.6 819.3 283.2 820.6 295.4 818.3C307.3 816.1 322.2 809.9 340 799.6L348.5 794.7V724.7L337.9 730.8C325.2 738.2 316.7 739.1 312.4 733.7C307.9 728.5 305.6 717.6 305.6 701.1V622.8C305.6 602.4 303.6 586.3 299.6 574.4C295.6 562.6 287.7 555 275.8 551.6C287.7 534.5 295.6 517.8 299.6 501.4C303.6 485 305.5 466.6 305.5 446.2V367.9C305.5 351.4 307.8 338 312.3 327.5C316.5 317.2 325 308.4 337.8 301L348.4 294.9V224.9L339.9 229.8C322 240.2 307.1 251.1 295.3 262.7C283.1 274.4 273.5 286.9 266.4 300C259 313.3 253.8 327.5 250.7 342.6C247.6 357.3 246 373.1 246 390V478.9C246 495.4 243.2 510.4 237.5 523.8C231.8 537.3 222.6 547.7 209.9 555L210 624.5Z"
        fill="url(#paint0_linear)"
      />
      <path
        opacity="0.07"
        d="M532.5 367C519.8 374.4 510.6 374.6 504.9 367.6C499.2 360.7 496.4 349 496.4 332.5V243.6C496.4 226.7 494.8 212.7 491.7 201.6C488.6 190.1 483.5 181.8 476.4 176.9C469 172.1 459.4 170.8 447.5 173C435.3 175.3 420.3 181.6 402.5 191.9L394 196.8V266.8L404.6 260.7C417.3 253.3 426 252.3 430.5 257.5C434.7 262.9 436.9 273.8 436.9 290.3V368.6C436.9 389 438.9 405.1 442.9 417C446.9 428.8 454.8 436.4 466.7 439.8C454.8 456.9 446.9 473.6 442.9 490C438.9 506.4 437 524.8 437 545.2V623.5C437 640 434.9 653.4 430.6 663.7C426.1 674.2 417.4 683.1 404.7 690.4L394.1 696.5V766.5L402.6 761.6C420.4 751.3 435.4 740.3 447.6 728.6C459.5 717 469.1 704.6 476.5 691.3C483.6 678.2 488.7 664.1 491.8 648.9C494.9 634.2 496.5 618.3 496.5 601.5V512.6C496.5 496.1 499.3 481.1 505 467.7C510.7 454.2 519.9 443.8 532.6 436.5L532.5 367Z"
        fill="url(#paint1_linear)"
      />
      <path
        opacity="0.4"
        d="M250 654.5C262.7 647.1 271.9 646.9 277.6 653.9C283.3 660.8 286.1 672.5 286.1 689V777.9C286.1 794.8 287.7 808.8 290.8 819.9C293.9 831.4 299.2 839.6 306.5 844.4C313.6 849.3 323.2 850.6 335.4 848.3C347.3 846.1 362.2 839.9 380 829.6L388.5 824.7V754.7L377.9 760.8C365.2 768.2 356.7 769.1 352.4 763.7C347.9 758.5 345.6 747.6 345.6 731.1V652.8C345.6 632.4 343.6 616.3 339.6 604.4C335.6 592.6 327.7 585 315.8 581.6C327.7 564.5 335.6 547.8 339.6 531.4C343.6 515 345.5 496.6 345.5 476.2V397.9C345.5 381.4 347.8 368 352.3 357.5C356.5 347.2 365 338.4 377.8 331L388.4 324.9V254.9L379.9 259.8C362.1 270.1 347.2 281 335.3 292.6C323.1 304.3 313.5 316.8 306.4 329.9C299 343.2 293.8 357.4 290.7 372.5C287.6 387.3 286 403.1 286 420V508.9C286 525.4 283.2 540.4 277.5 553.8C271.8 567.2 262.7 577.7 249.9 585L250 654.5Z"
        fill="url(#paint2_linear)"
      />
      <path
        opacity="0.4"
        d="M572.5 397C559.8 404.4 550.6 404.6 544.9 397.6C539.2 390.7 536.4 379 536.4 362.5V273.6C536.4 256.7 534.8 242.7 531.7 231.6C528.6 220.1 523.5 211.8 516.4 206.9C509 202.1 499.4 200.8 487.5 203C475.3 205.3 460.3 211.6 442.5 221.9L434 226.8V296.8L444.6 290.7C457.3 283.3 466 282.3 470.5 287.5C474.7 292.9 476.9 303.8 476.9 320.3V398.6C476.9 419 478.9 435.1 482.9 447C486.9 458.8 494.8 466.4 506.7 469.8C494.8 486.9 486.9 503.6 482.9 520C478.9 536.4 477 554.8 477 575.2V653.5C477 670 474.9 683.4 470.6 693.7C466.1 704.2 457.4 713.1 444.7 720.4L434.1 726.5V796.5L442.6 791.6C460.4 781.3 475.4 770.3 487.6 758.6C499.5 747 509.1 734.6 516.5 721.3C523.6 708.2 528.7 694.1 531.8 678.9C534.9 664.2 536.5 648.3 536.5 631.5V542.6C536.5 526.1 539.3 511.1 545 497.7C550.7 484.2 559.9 473.8 572.6 466.5L572.5 397Z"
        fill="url(#paint3_linear)"
      />
      <path
        opacity="0.15"
        d="M794.5 184.2L623.6 282.9L623.7 692.2L794.6 593.5L794.5 184.2Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M826.6 247.1L690.7 325.6V363.2L826.6 284.7V247.1Z"
        fill="#F2C94C"
      />
      <path
        opacity="0.15"
        d="M773.4 410.7L642.8 486.1V544.7L773.4 469.3V410.7Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M773.5 339.8L642.7 415.3V428.9L773.5 353.4V339.8Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M773.5 366.4L660.4 431.7V445.3L773.5 380V366.4Z"
        className="ssp"
      />
      <path
        opacity="0.2"
        d="M794.5 184.2L623.6 282.9V337.8L794.5 239.1V184.2Z"
        fill="url(#paint5_linear)"
      />
      <path
        opacity="0.2"
        d="M751.8 236.6C751.8 244.9 756.7 248.8 762.7 245.4C768.7 241.9 773.5 232.4 773.5 224.1C773.5 215.8 768.6 211.9 762.6 215.3C756.6 218.8 751.8 228.3 751.8 236.6Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M584.5 563.7L413.6 662.4L413.7 1071.7L584.6 973L584.5 563.7Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M519.4 682.8L383.5 761.3V798.9L519.4 720.4V682.8Z"
        fill="#F2994A"
      />
      <path
        opacity="0.15"
        d="M563.4 721.7L432.7 797.2V855.7L563.4 780.2V721.7Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M563.5 805.3L432.7 880.8V894.4L563.5 819V805.3Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M563.5 829.9L450.4 895.2V908.8L563.5 843.5V829.9Z"
        className="ssp"
      />
      <path
        opacity="0.2"
        d="M584.5 563.7L413.6 662.4V717.3L584.5 618.6V563.7Z"
        fill="url(#paint7_linear)"
      />
      <path
        opacity="0.2"
        d="M541.8 616.2C541.8 624.5 546.7 628.4 552.7 625C558.7 621.5 563.5 612 563.5 603.7C563.5 595.4 558.6 591.5 552.6 594.9C546.6 598.3 541.8 607.9 541.8 616.2Z"
        className="ssp"
      />
      <path
        opacity="0.3"
        d="M614 620.4L911.6 448.6L911.5 603.7L640.2 760.4L614 799.3V620.4Z"
        fill="url(#paint8_linear)"
      />
      <path
        opacity="0.15"
        d="M690.5 614L755.7 576.4V590L690.5 627.6V614Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M690.5 641.3L852 548.1V561.7L690.5 654.9V641.3Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M690.5 668.6L885.3 556.1V569.7L690.5 682.2V668.6Z"
        className="ssp"
      />
      <path
        d="M674.5 637.5C674.5 650.3 666.9 665 657.6 670.4C648.3 675.8 640.7 669.8 640.7 657C640.7 644.2 648.3 629.5 657.6 624.1C666.9 618.7 674.5 624.7 674.5 637.5Z"
        fill="#F2994A"
      />
      <path
        opacity="0.15"
        d="M882.2 339.8L921.6 317V303.7L882.2 326.5C879 328.4 874.3 332.1 870.3 337.9C866 344.1 862.9 351.9 862.9 360.4C862.9 369.8 865.3 375.1 869.8 376.7C874.1 378.1 879.1 375.7 882.2 373.9L898.4 364.5L898.5 364.4C899.9 363.6 902.1 362.8 903.8 363.3C905.2 363.7 906.9 365.2 906.9 370.4C906.9 375.6 905.3 378.8 903.9 380.7C902.2 383.1 900 384.8 898.6 385.5L898.4 385.6L848.8 414.2V427.5L898.1 399C901.5 397.2 906.4 393.5 910.6 387.6C915.2 381.1 918.4 372.9 918.4 363.7C918.4 354.6 915.2 349.9 910.7 348.6C906.5 347.4 901.6 349.2 898.2 351.2L882.1 360.5C879.8 361.8 878 362.4 876.9 362C876 361.7 874.4 360.4 874.4 353.7C874.4 349.3 875.8 346.5 877.2 344.5C878.8 342.3 880.9 340.5 882.2 339.8Z"
        className="ssp"
      />
      <path
        d="M909.9 318.4C909.9 329.8 916.6 335.1 924.8 330.4C933 325.7 939.7 312.6 939.7 301.2C939.7 289.8 933 284.5 924.8 289.2C916.5 294 909.9 307.1 909.9 318.4Z"
        fill="#F2C94C"
      />
      <path
        d="M830.9 428.4C830.9 439.8 837.6 445.2 845.8 440.4C854 435.6 860.7 422.6 860.7 411.2C860.7 399.8 854 394.4 845.8 399.2C837.5 404 830.9 417.1 830.9 428.4Z"
        fill="#F2C94C"
      />
      <path
        opacity="0.2"
        d="M724.6 776.1C718.6 779.6 713.8 787.9 713.8 794.9V825.4L693.6 837C687.6 840.5 682.8 848.8 682.8 855.8C682.8 862.7 687.7 865.5 693.6 862.1L713.8 850.5V881C713.8 887.9 718.7 890.7 724.6 887.3C730.6 883.8 735.4 875.5 735.4 868.5V838L755.6 826.4C761.6 822.9 766.4 814.6 766.4 807.6C766.4 800.7 761.5 797.9 755.6 801.3L735.4 812.9V782.4C735.4 775.4 730.6 772.6 724.6 776.1Z"
        fill="url(#paint9_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="147.056"
          y1="245.119"
          x2="393.686"
          y2="672.566"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="265.644"
          y1="174.847"
          x2="512.274"
          y2="602.295"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="187.056"
          y1="275.118"
          x2="433.686"
          y2="702.566"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="305.644"
          y1="204.847"
          x2="552.274"
          y2="632.295"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="620.469"
          y1="284.653"
          x2="783.737"
          y2="567.516"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="735.833"
          y1="185.036"
          x2="683.82"
          y2="332.513"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="410.477"
          y1="664.178"
          x2="573.745"
          y2="947.042"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="525.841"
          y1="564.561"
          x2="473.828"
          y2="712.038"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="678.938"
          y1="558.332"
          x2="832.589"
          y2="678.636"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="703.71"
          y1="815.063"
          x2="756.083"
          y2="856.368"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </StyledSvg>
  );
};

export default TestIcon;

const StyledSvg = styled.svg`
  .sr1 {
    fill: none;
  }

  .ss1 {
    stop-color: ${({ theme }) => theme.colors.text};
  }

  .ssp {
    fill: ${({ theme }) => theme.colors.text};
  }
`;

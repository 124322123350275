import React from "react";
import styled from "styled-components";

import { animateScroll as scroll } from "react-scroll";

import ChevronUpIcon from "../../images/icons/chevron-up-icon";

const ScrollToTop = ({ headerBlur }) => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
      <ScrollToTopStyling onClick={scrollToTop} headerBlur={headerBlur}>
        <ChevronUpIcon />
      </ScrollToTopStyling>
  );
};

export default ScrollToTop;

const ScrollToTopStyling = styled.div`
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  width: 60px;
  height: 58px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.body};
  box-shadow: ${({ theme }) => ` 0 1px 8px 2px ${theme.colors.text}66`};
  transition: 0.5s ease-in-out all;
  opacity: ${({ headerBlur }) => (headerBlur ? 1 : 0)};
  z-index:99;

  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}px) {
    bottom: 3em;
  }
`;

import React from "react";
import styled from "styled-components";

const MessageIcon = () => {
  return (
    <StyledSvg
      width="224"
      height="224"
      viewBox="0 0 1150 1150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1150" height="1150" rx="200" fill="none" />
      <path
        opacity="0.07"
        d="M632.4 127.2L185.5 627.1L632.5 611.1C635.8 611 639.4 605.6 638.6 602L593.7 402.3L368.4 515L596.2 366.1L638.5 129.4C639.3 124.8 635.7 123.6 632.4 127.2Z"
        fill="url(#paint0_linearm)"
      />
      <path
        opacity="0.4"
        d="M666.7 152.1L219.8 652L666.8 636C670.1 635.9 673.7 630.5 672.9 626.9L628 427.2L402.7 539.9L630.5 391L672.7 154.3C673.6 149.7 670 148.5 666.7 152.1Z"
        fill="url(#paint1_linearm)"
      />
      <path
        opacity="0.3"
        d="M633.8 680.2L931.4 508.4L931.3 663.5L659.9 820.1L633.7 859L633.8 680.2Z"
        fill="url(#paint2_linearm)"
      />
      <path d="M963 568.9V526.8L850.7 591.6V633.7L963 568.9Z" fill="#F2994A" />
      <path
        opacity="0.15"
        d="M710.3 673.8L775.5 636.2V649.8L710.3 687.4V673.8Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M710.2 701L835.9 628.5V642.1L710.2 714.6V701Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M710.2 728.3L862.5 640.4V654L710.2 741.9V728.3Z"
        className="ssp"
      />
      <path
        d="M694.2 697.2C694.2 710 686.6 724.7 677.3 730.1C668 735.5 660.4 729.5 660.4 716.7C660.4 703.9 668 689.2 677.3 683.8C686.7 678.4 694.2 684.4 694.2 697.2Z"
        fill="#F2994A"
      />
      <path
        opacity="0.3"
        d="M608.1 552.1L310.5 723.8L310.4 878.9L581.7 722.3L607.9 730.9L608.1 552.1Z"
        fill="url(#paint3_linearm)"
      />
      <path
        d="M397.1 751.2V709.1L284.8 773.9V816L397.1 751.2Z"
        fill="#F2C94C"
      />
      <path
        opacity="0.15"
        d="M531.5 634L466.3 671.6V685.2L531.5 647.6V634Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M531.5 661.3L413.8 729.2V742.8L531.5 674.9V661.3Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M531.5 688.5L379.2 776.4V790L531.5 702.1V688.5Z"
        className="ssp"
      />
      <path
        d="M547.6 638.9C547.6 651.7 555.2 657.7 564.5 652.3C573.8 646.9 581.4 632.2 581.4 619.4C581.4 606.6 573.8 600.6 564.5 606C555.2 611.4 547.6 626.2 547.6 638.9Z"
        fill="#F2C94C"
      />
      <path
        opacity="0.2"
        d="M269.8 931.3L447.5 828.7V921.3L269.8 1023.9V931.3Z"
        fill="url(#paint4_linearm)"
      />
      <path
        opacity="0.15"
        d="M402 877.3L348.6 908.2V919.3L402 888.4V877.3Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M402 899.6L288.2 965.3V976.6L402 910.9V899.6Z"
        className="ssp"
      />
      <path
        d="M411.4 880.6C411.4 888.2 415.9 891.8 421.5 888.6C427.1 885.4 431.6 876.6 431.6 869C431.6 861.4 427.1 857.8 421.5 861C415.9 864.2 411.4 873 411.4 880.6Z"
        fill="#F2C94C"
      />
      <path
        opacity="0.2"
        d="M761.8 789.3L939.5 686.7V779.3L761.8 881.9V789.3Z"
        fill="url(#paint5_linearm)"
      />
      <path
        opacity="0.15"
        d="M894 735.3L840.6 766.2V777.3L894 746.4V735.3Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M894 757.6L780.2 823.3V834.6L894 768.9V757.6Z"
        className="ssp"
      />
      <path
        d="M903.4 738.6C903.4 746.2 907.9 749.8 913.5 746.6C919.1 743.4 923.6 734.6 923.6 727C923.6 719.4 919.1 715.8 913.5 719C907.9 722.2 903.4 731 903.4 738.6Z"
        fill="#F2994A"
      />
      <path
        opacity="0.2"
        d="M736.3 494.8L779.1 470.1V455.7L736.3 480.4C732.8 482.4 727.7 486.5 723.4 492.8C718.7 499.5 715.4 508 715.4 517.2C715.4 527.4 718 533.2 722.9 534.8C727.5 536.3 733 533.7 736.3 531.8L753.9 521.6L754 521.5C755.5 520.6 757.9 519.7 759.8 520.3C761.4 520.8 763.1 522.3 763.1 528C763.1 533.7 761.3 537.1 759.9 539.2C758.1 541.8 755.7 543.6 754.2 544.4L754 544.5L700.2 575.6V590L753.7 559.1C757.4 557.2 762.7 553.1 767.3 546.7C772.3 539.6 775.7 530.7 775.7 520.8C775.7 510.9 772.3 505.9 767.4 504.5C762.8 503.2 757.5 505.1 753.8 507.3L736.4 517.3C733.9 518.8 732 519.4 730.7 519C729.7 518.7 727.9 517.3 727.9 510C727.9 505.2 729.4 502.1 730.9 500.1C732.6 497.5 734.9 495.6 736.3 494.8Z"
        className="ssp"
      />
      <path
        d="M771.3 467C771.3 479.9 778.9 486 788.2 480.7C797.5 475.3 805.1 460.5 805.1 447.5C805.1 434.6 797.5 428.5 788.2 433.8C778.9 439.2 771.3 454.1 771.3 467Z"
        fill="#F2994A"
      />
      <path
        d="M678.3 595C678.3 607.9 685.9 614 695.2 608.7C704.5 603.3 712.1 588.5 712.1 575.5C712.1 562.6 704.5 556.5 695.2 561.8C685.9 567.2 678.3 582.1 678.3 595Z"
        fill="#F2C94C"
      />
      <path
        opacity="0.2"
        d="M559.5 801.7V886C559.5 886.9 558.6 887.9 558 887.6L538.3 875.5L518.2 911C517.1 912.9 515.2 913.1 515.2 911.3V827.3L559.5 801.7Z"
        fill="url(#paint6_linearm)"
      />
      <defs>
        <linearGradient
          id="paint0_linearm"
          x1="352.847"
          y1="298.765"
          x2="556.491"
          y2="567.697"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linearm"
          x1="387.101"
          y1="323.675"
          x2="590.746"
          y2="592.607"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linearm"
          x1="698.66"
          y1="618.046"
          x2="852.311"
          y2="738.35"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linearm"
          x1="480.245"
          y1="557.096"
          x2="441.774"
          y2="869.128"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linearm"
          x1="338.603"
          y1="891.585"
          x2="378.686"
          y2="961.03"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linearm"
          x1="830.602"
          y1="749.583"
          x2="870.687"
          y2="819.028"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linearm"
          x1="518.177"
          y1="844.791"
          x2="568.599"
          y2="876.724"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </StyledSvg>
  );
};

export default MessageIcon;

const StyledSvg = styled.svg`
  .ss1 {
    stop-color: ${({ theme }) => theme.colors.text};
  }
  .ssp {
    fill: ${({ theme }) => theme.colors.text};
  }
`;

import React from "react";
import styled from "styled-components";

const ChevronUpIcon = () => {
  return (
    <StyledSvg
      height="32"
      viewBox="0 0 551.13 551.13"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m275.565 189.451 223.897 223.897h51.668l-275.565-275.565-275.565 275.565h51.668z" />
    </StyledSvg>
  );
};

export default ChevronUpIcon;

const StyledSvg = styled.svg`
  
  & path {
    fill: ${({ theme }) => theme.colors.text};
  }
`;


import React from "react";
import styled from "styled-components";
import { Element } from "react-scroll";

import jacques from "../../images/jacques.jpg";
import pieter from "../../images/pieter.png";
import madeleen from "../../images/madeleen.jpg";
import andre from "../../images/andre.jpg";

import StyledCard from "../reusable-components/styled-card";

const teamData = [
  {
    Photo: jacques,
    name: "Jacques Viviers",
    title: "Director",
    education: "Systems Engineer",
    bio:
        "<p>He has worked in the defence environment since 2004.  Although " +
        "his main focus was on software engineering, he is also comfortable with " +
        "electronic hardware design, development and testing.  His work was " +
        "always in the context of an interdisciplinary systems engineering " +
        "team. From 2015 to 2021 he was responsible for the technical integrity " +
        "of all engineering software development as Technical Manager: " +
        "Software. </p>" +
        "<p>He has a passion for creating systems of high quality and " +
        "it is his goal to work in a correct, effective, innovative, and " +
        "efficient way. He believes in continuous personal and professional " +
        "improvement, for himself as well as for each member of his team. He " +
        "places great value in his wonderful wife and beautiful daughters. </p>",
  },
  {
    Photo: pieter,
    name: "Pieter van Heerden",
    title: "Director",
    education: "Systems Engineer",
    bio: "<p>His career commenced in 2006 working in the landward defence environment. " + 
          "His main focus was on design implementation of tracking and fire control " +
          "systems in the military combat environment. The majority of his time was spent " +
          "performing requirement analyses, software design, implementation, testing and " +
          "qualification. He is further experienced in electronic hardware design, " + 
          "development and testing. </p>" +
          "<p>He has a broad technical knowledge of system design, " + 
          "integration, testing and optimisation executed on various major defence projects." + 
          " For the last 10 months he participated in software system engineering " + 
          "consultation work with the main focus on requirement generation, safe software, " + 
          "airborne product development and qualification within a MIL-STD-498 and " + 
          "RTCA/DO-178C context.</p>",
  },
  {
    Photo: andre,
    name: "André van Heerden",
    title: "Consultant",
    education: "PhD Mechanical Engineering",
    bio:  "<p>He has 39 years of experience in the design, development, optimization, " +
          "manufacturing, maintenance, test & evaluation/ qualification of SSC (Systems, " +
          "Structure and Components). His proven successes is serviced by his theoretical " +
          "knowledge and experience covered by his career exposure and growth as Senior " +
          "Engineering Consultant, Chief Systems Engineer, Chief Design Engineer, Project " +
          "Manager, Engineering Assistant / Technologist, Draftsman, Technician and " +
          "Artisan. </p>" +
          "<p>He is knowledgeable and experienced to operate within a multi " +
          "disciplinary environment. He was involved in many areas, including nuclear, " +
          "armament (artillery, air, naval, armour, infantry weapon systems and " +
          "platforms), civil, aviation as well as rail transport. He has extensive " +
          "experience in Project Management, System Engineering, Interface Management, " +
          "Requirement Management, Design, Simulation, Compliance Management, Test & " +
          "Evaluation, and Qualification of Equipment.</p>",
  },
  {
    Photo: madeleen,
    name: "Madeleen Roestorff",
    title: "Consultant",
    education: "MEng Environmental Engineering, BEng Chemical Engineering",
    bio:  "<p>Madeleen is a very versatile engineer who is always up for new and " +
          "exciting challenges. She completed her undergraduate degree in " +
          "chemical engineering at the University of Pretoria and has a " +
          "fondness for process optimisation and programming. Madeleen " +
          "went on to do her Masters in Environmental Engineering where she " +
          "published multiple  " + 
          "<a href=\"https://scholar.google.com/scholar?hl=en&quot;&as_sdt=0%2C5&q=maria+roestorff+cr&btnG=\"> papers </a> " + 
          "and considers herself a competent independent researcher.</p>" +
          "<p>In the past two years she started concentrating more on " + 
          "software engineering. During this time she has spent time expanding her " + 
          "expertise in backend and frontend software development, as well as the " + 
          "indirect aspects of software engineering such as software design, " + 
          "modelling, and review. Favourite programming languages: python, javascript (including HTML & CSS) and VBA. </p>",
  },
      
];

const Team = () => {
  return (
    <>
      <h2>
        <Element name="team">Our Team</Element>
      </h2>
      <CardContainer>
        {teamData.map(({ Photo, name, title, education, bio }, teamIndex) => {
          return !(name && title && education && bio) ? null : (
            <StyledCard
              className="team"
              key={`team-info-${teamIndex}`}
              customPadding="0.65em 1.5em"
            >
              <h3>{name}</h3>
              <ContentStyling>
                <IconStyling photo={Photo} />
                <ContentColumnStyling>
                  <p>{title}</p>
                  <p>{education}</p>
                  <p dangerouslySetInnerHTML={{__html: bio}}/>
                </ContentColumnStyling>
              </ContentStyling>
            </StyledCard>
          );
        })}
      </CardContainer>
    </>
  );
};

export default Team;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ContentStyling = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}px) {
    flex-direction: column;
    justify-content: center;
  }
`;
const ContentColumnStyling = styled.div`
  width: 75%;
  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}px) {
    width: calc(100% - 30px);
  }
`;
const IconStyling = styled.div`
  width: 280px;
  height: 280px;
  margin: 1px 1.5em 1px 1px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-image: url(${({ photo }) => photo});

  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}px) {
    margin-right: 1px;
    margin-top: 20px;
    width: 222px;
    height: 222px;
  }
`;

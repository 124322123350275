import React from "react";
import styled from "styled-components";
import { Element } from "react-scroll";

import MessageIcon from "../../images/icons/message-icon";
import LocationIcon from "../../images/icons/location-icon";
import StyledCard from "../reusable-components/styled-card";

const Contact = ({ darkMode }) => {
  return (
    <div className="full-width-section">
      <h2>
        <Element name="contact">Contact Us</Element>
      </h2>
      <ContactLayoutStyling>
        <MapStyling darkMode={darkMode}>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3591.772812065812!2d28.310547215021835!3d-25.811068183614296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e955dee730ff5ed%3A0x627769eecfab71fb!2sX13%2C%201015%20Marlin%20St%2C%20Garsfontein%2C%20Pretoria%2C%200042!5e0!3m2!1sen!2sza!4v1618226883017!5m2!1sen!2sza"
            width="100%"
            height="100%"
            loading="lazy"
            rameborder="0"
          ></iframe>
        </MapStyling>
        <DetailsColumnStyling>
          <StyledCard customPadding="0.4em" customMargin="0 0 1.8em">
            <DetailsStyling>
              <MessageIcon />
              <p>
                <div>
                  <div>Contact Information</div>
                  <div><span>Jacques: </span><a href="tel:27822910156"> +27 82 291 0156</a></div>
                  <div><span>Pieter: </span><a href="tel:27829736502"> +27 82 973 6502</a></div>
                </div>
                <div>
                  <span>Email Address: </span>
                  <a href="mailto:info@eamekh.co.za">info@eamekh.co.za </a>
                </div>
              </p>
            </DetailsStyling>
          </StyledCard>
          <StyledCard customPadding="0.4em" customMargin="0">
            <DetailsStyling>
              <LocationIcon />
              <p>
                <div> Postal Address </div>
                <div>1015 Marlin Street</div>
                <div>Garsfontein X13 </div>
                <div>Pretoria </div>
              </p>
            </DetailsStyling>
          </StyledCard>
        </DetailsColumnStyling>
      </ContactLayoutStyling>
    </div>
  );
};

export default Contact;

const MapStyling = styled.div`
  display: flex;
  width: 50%;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}px) {
    width: 100%;
    margin-top: calc(1em + 20px);
  }
  & iframe {
    filter: ${({ darkMode }) =>
      darkMode ? "grayscale(100%) invert(93%) contrast(100%)" : "none"};
    border: 0;
    height: 100%;
    @media (max-width: ${({ theme }) => theme.breakPoints.mobile}px) {
      height: 300px;
    }
  }
  &:after {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: ${({ theme, darkMode }) =>
      darkMode ? theme.colors.body : "transparent"};
    mix-blend-mode: screen;
    pointer-events: none;
  }
`;
const ContactLayoutStyling = styled.div`
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  margin-top: calc(1em + 20px);
  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}px) {
    flex-direction: column-reverse;
    margin-bottom: 4em;
  }
`;
const DetailsStyling = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}px) {
    & > svg {
      width: 45%;
      height: 100%;
    }
  }
`;
const DetailsColumnStyling = styled.div`
  margin-left: calc(4vw + 15px);
  margin-right: calc(4vw + 15px);
  width: calc(50% - 30px - 8vw);

  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}px) {
    width: calc(100% - 30px - 8vw);
  }
`;

import React from "react";
import styled from "styled-components";
import { scroller } from "react-scroll";

const scrollTo = (navName) => {
  scroller.scrollTo(navName, {
    duration: 900,
    delay: 30,
    smooth: true,
    offset: -90, // Scrolls to element + 50 pixels down the page
  });
};

const navNameData = ["about", "team", "contact"];

const Navigation = ({ headerBlurStyling, belowBanner }) => {

  return (
    <NavigationStyling
    headerBlurStyling={headerBlurStyling}
    belowBanner={belowBanner}
    >
      {navNameData.map((navItem, navIndex) => {
        return (
          <div key={`ref-nav-${navIndex}`} onClick={() => scrollTo(navItem)}>
            {navItem}
          </div>
        );
      })}
    </NavigationStyling>
  );
};

export default Navigation;

const NavigationStyling = styled.nav`
  display: flex;
  width: 100%;
  height: calc(100% + 45px);
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 15px 0;
  backdrop-filter: ${({ headerBlurStyling }) =>
    headerBlurStyling ? "blur(7px)" : "blur(0)"};
  background-color: ${({ theme, belowBanner }) =>
    `${theme.colors.body}${belowBanner ? "ee" : "cc"}`};
  transition: 0.3s ease-in-out all;

  & > div {
    margin: 0 2vw;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    text-decoration: none;
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
      0px 18px 23px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: ${({ theme }) => theme.colors.text};
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }
    &:hover::before {
      visibility: visible;
      width: 100%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}px) {
    backdrop-filter: blur(7px);
    position: fixed;
    bottom: 0px;
    transition: 0.5s ease-in-out all;
    height: 2em;
  }
`;

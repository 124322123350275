import React, { forwardRef } from "react";
import styled from "styled-components";
import SunIcon from "../../images/icons/sun-icon";
import MoonIcon from "../../images/icons/moon-icon";
import Navigation from "./navigation";

const Header = forwardRef(
  ({ headerBlur, belowBanner, darkMode, setMode = () => {} }, headerRef) => {
    return (
      <HeaderStyling
        ref={headerRef}
        belowBanner={belowBanner}
      >
        <Navigation headerBlurStyling={headerBlur} belowBanner={belowBanner} />
        <SwitcherWrapper
          headerBlurStyling={headerBlur}
          belowBanner={belowBanner}
        >
          <SunIcon darkMode={darkMode} />
          <Switcher>
            <input
              type="checkbox"
              id="switch"
              name="lightDarkModeSwitch"
              checked={darkMode}
              onChange={setMode}
            />
          </Switcher>
          <MoonIcon darkMode={darkMode} />
        </SwitcherWrapper>
      </HeaderStyling>
    );
  }
);

export default Header;

const Switcher = styled.div`
  display: flex;
  align-items: center;
  input[type="checkbox"] {
    position: relative;
    width: 70px;
    height: 35px;
    appearance: none;
    background-color: ${({ theme }) => theme.colors.accent};
    border-radius: 20px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    transition: 0.3s ease-in-out all;
    outline: none;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      width: 50%;
      height: 100%;
      border-radius: 20px;
      background-color: white;
      top: 0px;
      bottom: 0px;
      left: 0px;
      transform: scale(0.8);
      transform-origin: center center;
      transition: 0.3s ease-in-out all;
      transform-box: fill-box;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }
  }
  input:checked[type="checkbox"] {
    background-color: ${({ theme }) => theme.colors.accent};
    &::before {
      left: 50%;
    }
  }
`;

const HeaderStyling = styled.header`
  width: 100%;
  position: fixed;
  height: fit-content;
  top: 0;
  z-index: 99;
  display: flex;
  box-shadow: ${({ belowBanner, theme }) =>
    belowBanner ? `0 1px 8px 2px ${theme.colors.headerShadow}` : ""};
  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}px) {
    position: relative;
  }
`;

const SwitcherWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  right: calc(4vw + 15px);

  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}px) {
    width: 100%;
    justify-content: flex-end;
    position: fixed;
    height: 2.8em;
    right: 0;
    padding-right: calc(4vw + 5px);
    backdrop-filter: ${({ headerBlurStyling }) =>
      headerBlurStyling ? "blur(7px)" : "blur(0)"};
    background-color: ${({ theme, belowBanner }) =>
      `${theme.colors.body}${belowBanner ? "ee" : "cc"}`};
    transition: 0.3s linear all;
  }
`;

import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
    font-family: JostLight;
    transition: all 0.50s linear;
    box-sizing: border-box;
  }

  p, li {
    font-size:0.85em;
  }

  h1 {
    margin: 0;
    line-height: 1;
    font-weight: 300;
    letter-spacing: 5px;
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
      0px 18px 23px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }

  h2 {
    padding-top:1em;
    font-size: 1.5em;
    margin: 20px 0;
    line-height: 1.2;
    font-weight: 300;
    letter-spacing: 5px;
    text-transform: uppercase;
    text-align: center;
  }

  h3 {
    font-size: 1.1em;
    margin: 0.5em 0 0;
    line-height: 1.4;
    font-weight: 300;
    text-align: center;

  }

  h1 {
    color: ${({ theme }) => theme.colors.themeShade};
  }


  button {
    border: 0;
    display: inline-block;
    padding: 12px 24px;
    border-radius: 4px;
    margin-top: 5px;
    cursor: pointer;
    background-color: #1064EA;
    color: #FFFFFF;
    font-family: JostLight;
  }

  button.btn {
    background-color: ${({ theme }) => theme.colors.button.background};
    color: ${({ theme }) => theme.colors.button.text};
  }
  ul {
    @media (max-width: ${({ theme }) => theme.breakPoints.mobile}px) {
      margin-block-start: 0;
      margin-block-end: 0.6em;
    }
  }

  a {
    display: inline-block;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.text};
    text-decoration:none;
    position: relative;
    width:fit-content;
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: ${({ theme }) => theme.colors.text};
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }
    &:hover::before {
      visibility: visible;
      width: 100%;
    }
  }

`;

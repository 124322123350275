import React from "react";
import styled from "styled-components";

const MoonIcon = ({ darkMode }) => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      height="28px"
      width="28px"
      viewBox="0 0 480.737 480.737"
    >
      <StyledPath
        darkMode={darkMode}
        d="M224.738,480.719c-92.851-0.072-178.412-50.321-223.704-131.376
      c-2.171-3.848-0.812-8.727,3.036-10.899c2.442-1.378,5.427-1.377,7.868,0.003c115.906,64.431,262.097,22.702,326.528-93.204
      c40.328-72.547,40.328-160.777,0-233.324c-2.155-3.857-0.775-8.731,3.082-10.886c2.428-1.357,5.387-1.354,7.814,0.006
      c123.504,68.885,167.782,224.847,98.896,348.352C403.055,430.437,317.538,480.682,224.738,480.719L224.738,480.719z M29.898,364.927
      c77.541,107.501,227.547,131.789,335.048,54.248s131.789-227.547,54.248-335.048c-15.057-20.874-33.373-39.191-54.248-54.248
      c54.52,130.522-7.092,280.528-137.614,335.048C164.165,391.312,93.065,391.312,29.898,364.927z"
      />
    </StyledSvg>
  );
};

export default MoonIcon;

const StyledSvg = styled.svg`
  min-width: 28px;
`;

const StyledPath = styled.path`
  fill: ${({ theme, darkMode }) =>
    darkMode ? theme.colors.iconColor : theme.colors.accent};
  transition: all 0.5s linear;
`;

import React from "react";
import styled from "styled-components";

const ShieldIcon = () => {
  return (
    <StyledSvg
      width="128"
      height="128"
      viewBox="0 0 1150 1150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1150" height="1150" rx="200" fill="none" />
      <path
        opacity="0.2"
        d="M623.8 331.3L839.2 209.3L840.2 321L624.7 443L623.8 331.3Z"
        fill="url(#paint0_linears)"
      />
      <path
        opacity="0.15"
        d="M784.3 267.5L719.5 304.2L719.6 317.6L784.4 280.9L784.3 267.5Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M784.5 294.4L646.5 372.5L646.6 386.1L784.6 308L784.5 294.4Z"
        className="ssp"
      />
      <path
        d="M795.8 271.5C795.9 280.7 801.4 285.1 808.2 281.2C815 277.4 820.4 266.8 820.3 257.6C820.2 248.4 814.7 244 807.9 247.9C801.1 251.8 795.7 262.3 795.8 271.5Z"
        fill="#F2C94C"
      />
      <path
        opacity="0.2"
        d="M133.7 831.7L349.2 709.8L350.2 821.5L134.6 943.4L133.7 831.7Z"
        fill="url(#paint1_linears)"
      />
      <path
        opacity="0.15"
        d="M294.3 767.9L229.5 804.6L229.6 818L294.4 781.3L294.3 767.9Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M294.4 794.8L156.4 872.9L156.5 886.5L294.5 808.4L294.4 794.8Z"
        className="ssp"
      />
      <path
        d="M305.7 772C305.8 781.2 311.3 785.6 318.1 781.7C324.9 777.9 330.3 767.3 330.2 758.1C330.1 748.9 324.6 744.5 317.8 748.4C311 752.2 305.6 762.7 305.7 772Z"
        fill="#F2C94C"
      />
      <path
        opacity="0.1"
        d="M385 116.6C425.6 124.5 516.9 134.7 556.7 112.2C556.4 271.4 517.4 497.3 389.9 683.6C260.4 642.7 217.9 462.9 214.9 305.7C254.7 283.1 344.9 170.2 385 116.6Z"
        fill="url(#paint2_linears)"
      />
      <path
        opacity="0.3"
        d="M434.6 149.6C475.2 157.5 566.5 167.7 606.3 145.2C606 304.3 567 530.2 439.5 716.5C310 675.6 267.5 495.9 264.5 338.6C304.3 316 394.5 203.2 434.6 149.6Z"
        fill="url(#paint3_linears)"
      />
      <path
        opacity="0.4"
        d="M264.5 338.6C304.4 316 394.5 203.2 434.6 149.6L439.5 716.5C310 675.6 267.5 495.9 264.5 338.6Z"
        fill="url(#paint4_linears)"
      />
      <path
        opacity="0.1"
        d="M648.4 448.9L448.9 561.8L453 1037.5L652.4 924.6L648.4 448.9Z"
        fill="url(#paint5_linears)"
      />
      <path
        opacity="0.2"
        d="M624.8 664.8L474.4 749.9L475.5 882.1L626 797L624.8 664.8Z"
        fill="url(#paint6_linears)"
      />
      <path
        opacity="0.15"
        d="M621.1 576.8L551.6 616.1L551.7 629.7L621.2 590.4L621.1 576.8Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M621.1 603.8L551.6 643.1L551.7 656.7L621.2 617.4L621.1 603.8Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M621.6 629.8L489.6 704.5L489.7 718.1L621.7 643.4L621.6 629.8Z"
        className="ssp"
      />
      <path
        opacity="0.2"
        d="M648.4 448.9L448.9 561.8L449.5 625.5L648.9 512.6L648.4 448.9Z"
        fill="url(#paint7_linears)"
      />
      <path
        opacity="0.2"
        d="M598.8 509.4C598.9 519 604.6 523.6 611.6 519.7C618.6 515.7 624.2 504.7 624.1 495.1C624 485.5 618.3 480.9 611.3 484.8C604.3 488.8 598.7 499.8 598.8 509.4Z"
        className="ssp"
      />
      <path
        d="M531.7 664.6L531.2 609.8L396 686.3L396.5 741.1L531.7 664.6Z"
        fill="#F2C94C"
      />
      <path
        opacity="0.3"
        d="M687.4 433.3L985 261.5L984.9 416.6L713.5 573.2L687.2 612.1L687.4 433.3Z"
        fill="url(#paint8_linears)"
      />
      <path
        d="M1016.6 322V279.9L904.3 344.7V386.8L1016.6 322Z"
        fill="#F2994A"
      />
      <path
        opacity="0.15"
        d="M763.8 426.9L829 389.3V402.9L763.8 440.5V426.9Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M763.8 454.1L889.5 381.6V395.2L763.8 467.7V454.1Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M763.8 481.4L916.1 393.5V407.1L763.8 495V481.4Z"
        className="ssp"
      />
      <path
        d="M747.8 450.4C747.8 463.2 740.2 477.9 730.9 483.3C721.6 488.7 714 482.7 714 469.9C714 457.1 721.6 442.4 730.9 437C740.2 431.6 747.8 437.6 747.8 450.4Z"
        fill="#F2994A"
      />
      <path
        opacity="0.2"
        d="M783.1 593.1C777.1 596.5 772.3 604.9 772.3 611.8V642.3L752.1 654C746.1 657.4 741.3 665.8 741.3 672.7C741.3 679.6 746.1 682.4 752.1 679L772.3 667.4V697.9C772.3 704.8 777.1 707.6 783.1 704.2C789.1 700.8 793.9 692.4 793.9 685.5V655L814.1 643.4C820.1 640 824.9 631.6 824.9 624.7C824.9 617.8 820.1 615 814.1 618.4L793.9 630V599.5C793.9 592.5 789.1 589.7 783.1 593.1Z"
        fill="url(#paint9_linears)"
      />
      <defs>
        <linearGradient
          id="paint0_linears"
          x1="707.919"
          y1="283.645"
          x2="756.028"
          y2="368.664"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linears"
          x1="217.84"
          y1="784.053"
          x2="265.949"
          y2="869.072"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linears"
          x1="646.756"
          y1="167.999"
          x2="368.195"
          y2="417.31"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linears"
          x1="696.347"
          y1="200.973"
          x2="417.786"
          y2="450.284"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linears"
          x1="249.966"
          y1="397.24"
          x2="473.426"
          y2="440.008"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linears"
          x1="675.552"
          y1="619.258"
          x2="467.455"
          y2="825.92"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linears"
          x1="598.183"
          y1="667.939"
          x2="510.257"
          y2="861.205"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linears"
          x1="580.265"
          y1="449.535"
          x2="519.428"
          y2="619.866"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linears"
          x1="752.247"
          y1="371.176"
          x2="905.898"
          y2="491.48"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linears"
          x1="762.219"
          y1="632.107"
          x2="814.577"
          y2="673.426"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </StyledSvg>
  );
};

export default ShieldIcon;

const StyledSvg = styled.svg`
  .ss1 {
    stop-color: ${({ theme }) => theme.colors.text};
  }
  .ssp {
    fill: ${({ theme }) => theme.colors.text};
  }
`;

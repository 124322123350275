import React from "react";
import styled from "styled-components";

const LocationIcon = () => {
  return (
    <StyledSvg
      width="224"
      height="224"
      viewBox="0 0 1150 1150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1150" height="1150" rx="200" fill="none" />
      <path
        opacity="0.07"
        d="M448.2 767.5C448.2 767.5 322.2 568.5 322.2 431.6C322.2 334.9 378.6 224.1 448.2 184C517.8 143.9 574.2 189.8 574.2 286.5C574.2 422.2 448.2 767.5 448.2 767.5ZM448.2 281.2C417.5 298.9 392.6 347.6 392.6 390.1C392.6 432.5 417.5 452.6 448.2 434.9C478.9 417.2 503.8 368.5 503.8 326C503.8 283.6 478.9 263.5 448.2 281.2Z"
        fill="url(#paint0_linearl)"
      />
      <path
        opacity="0.4"
        d="M488.2 797.5C488.2 797.5 362.2 598.5 362.2 461.6C362.2 364.9 418.6 254.1 488.2 214C557.8 173.9 614.2 219.8 614.2 316.5C614.2 452.2 488.2 797.5 488.2 797.5ZM488.2 311.2C457.5 328.9 432.6 377.6 432.6 420.1C432.6 462.5 457.5 482.6 488.2 464.9C518.9 447.2 543.8 398.5 543.8 356C543.8 313.6 518.9 293.5 488.2 311.2Z"
        fill="url(#paint1_linearl)"
      />
      <path
        opacity="0.3"
        d="M744.7 521.8L557.8 629.7V976L744.7 868.1V521.8Z"
        fill="url(#paint2_linearl)"
      />
      <path
        opacity="0.15"
        d="M715 742L603.6 806.3V819.9L715 755.6V742Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M703.5 775.7L615.2 826.6V840.2L703.5 789.2V775.7Z"
        className="ssp"
      />
      <path
        d="M673.3 837.3L803.3 762.2V800.9L673.3 876V837.3Z"
        fill="#F2C94C"
      />
      <path
        opacity="0.15"
        d="M652.7 635.5L691.6 613V599.9L652.7 622.4C649.5 624.2 644.9 628 641 633.7C636.8 639.8 633.7 647.5 633.7 655.9C633.7 665.1 636.1 670.4 640.5 671.9C644.7 673.3 649.7 670.9 652.7 669.1L668.7 659.9L668.8 659.8C670.2 659 672.4 658.2 674.1 658.7C675.5 659.1 677.1 660.5 677.1 665.7C677.1 670.8 675.5 674 674.2 675.9C672.5 678.2 670.4 679.9 669 680.6L668.8 680.7L619.8 709V722.1L668.4 694C671.7 692.3 676.6 688.6 680.7 682.7C685.3 676.3 688.4 668.2 688.4 659.1C688.4 650.1 685.3 645.5 680.8 644.2C676.6 643 671.8 644.7 668.5 646.8L652.7 655.9C650.4 657.2 648.7 657.8 647.5 657.4C646.6 657.1 645 655.9 645 649.2C645 644.9 646.4 642.1 647.7 640.2C649.3 638 651.4 636.3 652.7 635.5Z"
        className="ssp"
      />
      <path
        d="M677.7 616.7C677.7 629.6 685.3 635.7 694.6 630.4C703.9 625 711.5 610.2 711.5 597.2C711.5 584.3 703.9 578.2 694.6 583.5C685.3 588.9 677.7 603.8 677.7 616.7Z"
        fill="#F2C94C"
      />
      <path
        d="M594.7 730.7C594.7 743.6 602.3 749.7 611.6 744.4C620.9 739 628.5 724.2 628.5 711.2C628.5 698.3 620.9 692.2 611.6 697.5C602.3 702.9 594.7 717.8 594.7 730.7Z"
        fill="#F2C94C"
      />
      <path
        opacity="0.3"
        d="M631.2 378.1L928.8 206.3L928.7 361.4L657.4 518L631.1 556.9L631.2 378.1Z"
        fill="url(#paint3_linearl)"
      />
      <path
        d="M960.5 266.8V224.7L848.2 289.5V331.6L960.5 266.8Z"
        fill="#F2994A"
      />
      <path
        opacity="0.15"
        d="M707.7 371.7L772.9 334.1V347.7L707.7 385.3V371.7Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M707.7 398.9L833.4 326.4V340L707.7 412.5V398.9Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M707.7 426.2L860 338.3V351.9L707.7 439.8V426.2Z"
        className="ssp"
      />
      <path
        d="M691.7 395.1C691.7 407.9 684.1 422.6 674.8 428C665.5 433.4 657.9 427.4 657.9 414.6C657.9 401.8 665.5 387.1 674.8 381.7C684.1 376.4 691.7 382.4 691.7 395.1Z"
        fill="#F2994A"
      />
      <path
        opacity="0.3"
        d="M511.5 653.1L214 824.8L213.9 979.9L485.2 823.3L511.4 831.9L511.5 653.1Z"
        fill="url(#paint4_linearl)"
      />
      <path
        d="M300.5 852.2V810.1L188.2 874.9V917L300.5 852.2Z"
        fill="#F2994A"
      />
      <path
        opacity="0.15"
        d="M435 735L369.8 772.6V786.2L435 748.6V735Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M435 762.3L317.3 830.2V843.8L435 775.9V762.3Z"
        className="ssp"
      />
      <path
        opacity="0.15"
        d="M435 789.5L282.7 877.4V891L435 803.2V789.5Z"
        className="ssp"
      />
      <path
        d="M451 739.9C451 752.7 458.6 758.7 467.9 753.3C477.2 747.9 484.8 733.2 484.8 720.4C484.8 707.6 477.2 701.6 467.9 707C458.6 712.4 451.1 727.2 451 739.9Z"
        fill="#F2994A"
      />
      <path
        opacity="0.2"
        d="M837.9 630C905.5 543.2 856.6 512 837.9 562.8C819.2 533.7 770.3 621.3 837.9 630Z"
        fill="url(#paint5_linearl)"
      />
      <path
        opacity="0.2"
        d="M225.5 692.1L261 720.8C264.8 723.9 271.7 720.7 276.2 713.6C280.8 706.5 281.4 698.3 277.6 695.2L262.7 683.2L319.5 650.4C325.5 646.9 330.3 638.6 330.3 631.7C330.3 624.8 325.5 622 319.5 625.4L262.7 658.2L277.6 628.9C281.4 621.4 280.8 613.9 276.2 612.1C271.6 610.3 264.8 615 261 622.5L225.5 692.1Z"
        fill="url(#paint6_linearl)"
      />
      <defs>
        <linearGradient
          id="paint0_linearl"
          x1="225.704"
          y1="365.725"
          x2="575.472"
          y2="507.71"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linearl"
          x1="265.707"
          y1="395.725"
          x2="615.476"
          y2="537.71"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linearl"
          x1="537.791"
          y1="552.318"
          x2="703.701"
          y2="839.757"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linearl"
          x1="696.118"
          y1="315.971"
          x2="849.769"
          y2="436.275"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linearl"
          x1="383.701"
          y1="658.103"
          x2="345.23"
          y2="970.134"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linearl"
          x1="823.654"
          y1="566.118"
          x2="873.492"
          y2="609.269"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linearl"
          x1="240.915"
          y1="685.901"
          x2="336.701"
          y2="621.588"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="ss1" />
          <stop offset="1" className="ss1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </StyledSvg>
  );
};

export default LocationIcon;

const StyledSvg = styled.svg`
  .ss1 {
    stop-color: ${({ theme }) => theme.colors.text};
  }
  .ssp {
    fill: ${({ theme }) => theme.colors.text};
  }
`;

import React from "react";
import styled from "styled-components";

const SunIcon = ({ darkMode }) => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      height="32px"
      width="32px"
      viewBox="0 0 480 480"
      darkMode={darkMode}
    >
      <path
        d="M240,368c-70.692,0-128-57.308-128-128s57.308-128,128-128s128,57.308,128,128
      C367.916,310.658,310.658,367.916,240,368z M240,128c-61.856,0-112,50.144-112,112s50.144,112,112,112s112-50.144,112-112
      C351.934,178.172,301.828,128.066,240,128z"
      />
      <g>
        <path
          d="M240,96c-4.418,0-8-3.582-8-8V8c0-4.418,3.582-8,8-8s8,3.582,8,8v80
        C248,92.418,244.418,96,240,96z"
        />
        <path
          d="M132.52,140.52c-2.122,0-4.156-0.844-5.656-2.344L70.296,81.6
        c-3.251-2.992-3.461-8.053-0.469-11.304s8.053-3.461,11.304-0.469c0.163,0.15,0.319,0.306,0.469,0.469l56.568,56.568
        c3.124,3.125,3.123,8.19-0.002,11.314c-1.5,1.499-3.534,2.342-5.654,2.342L132.52,140.52z"
        />
      </g>
      <path d="M88,248H8c-4.418,0-8-3.582-8-8s3.582-8,8-8h80c4.418,0,8,3.582,8,8S92.418,248,88,248z" />
      <g>
        <path
          d="M75.952,412.048c-4.418-0.001-7.999-3.583-7.998-8.002c0-2.121,0.843-4.154,2.342-5.654
        l56.568-56.568c3.178-3.069,8.243-2.982,11.312,0.196c2.994,3.1,2.994,8.015,0,11.116L81.6,409.704
        C80.102,411.202,78.071,412.045,75.952,412.048z"
        />
        <path
          d="M240,480c-4.418,0-8-3.582-8-8v-80c0-4.418,3.582-8,8-8s8,3.582,8,8v80
        C248,476.418,244.418,480,240,480z"
        />
        <path
          d="M404.048,412.048c-2.122,0-4.156-0.844-5.656-2.344l-56.568-56.568
        c-3.07-3.178-2.982-8.242,0.196-11.312c3.1-2.995,8.016-2.995,11.116,0l56.568,56.576c3.124,3.125,3.123,8.19-0.002,11.314
        c-1.5,1.499-3.534,2.342-5.654,2.342V412.048z"
        />
      </g>
      <path d="M472,248h-80c-4.418,0-8-3.582-8-8s3.582-8,8-8h80c4.418,0,8,3.582,8,8S476.418,248,472,248z" />
      <path
        d="M347.48,140.52c-4.418-0.001-7.999-3.583-7.998-8.002c0-2.121,0.843-4.155,2.342-5.654
      L398.4,70.296c2.992-3.251,8.053-3.461,11.304-0.469c3.251,2.992,3.461,8.053,0.469,11.304c-0.15,0.163-0.306,0.319-0.469,0.469
      l-56.568,56.568C351.637,139.671,349.603,140.517,347.48,140.52L347.48,140.52z"
      />
    </StyledSvg>
  );
};

export default SunIcon;

const StyledSvg = styled.svg`
  min-width: 32px;
  & path {
    fill: ${({ theme, darkMode }) =>
      darkMode ? theme.colors.accent : theme.colors.iconColor};
    transition: all 0.5s linear;
  }
`;

import React, { forwardRef } from "react";
import headerBanner from "../../images/circuit-device-2.jpg";
import styled from "styled-components";

const TitleBanner = forwardRef(
  ({ titleInHeader }, { titleRef, bannerImgRef }) => {
    return (
      <BackgoundImageStyling ref={bannerImgRef} backgroundImage={headerBanner}>
        <TitleWrapper
          className={`${titleInHeader ? "title-in-header" : "title-in-center"}`}
        >
          <h1 ref={titleRef}>EAMEKH</h1>
        </TitleWrapper>
      </BackgoundImageStyling>
    );
  }
);

export default TitleBanner;

const BackgoundImageStyling = styled.div`
  width: 100%;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  height: 50vh;
  position: relative;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 50vh;
  transition: 0.5s ease-in-out all;
  pointer-events: none;
  &.title-in-header {
    width: calc(8vw + 7em + 60px);
    height: calc(2em + 14px);
    transition: 0.5s ease-in-out all;
    & h1 {
      transition: 0.5s ease-in-out font-size, 0s linear color;
      font-size: 2em;
      color: ${({ theme }) => theme.colors.text};
    }
  }
  & h1 {
    transition: 0.5s ease-in-out font-size, 0s linear color;
    font-size: 3em;
    padding: 7px 4vw;
    @media (max-width: 800px) {
      font-size: 2.2em;
    }
  }
`;

import React from "react";
import styled from "styled-components";

const StyledCard = ({
  children, //required
  className = "",
  customPadding = "0.65em",
  customWidth = "100%",
  customMargin = "0.95em 0",
}) => {
  return (
    <CardStyling
      customPadding={customPadding}
      customWidth={customWidth}
      className={className}
      customMargin={customMargin}
    >
      {children}
    </CardStyling>
  );
};

export default StyledCard;

const CardStyling = styled.div`
  margin: ${({ customMargin }) => customMargin};
  width: ${({ customWidth }) => customWidth};
  box-sizing: border-box;
  padding: ${({ customPadding }) => customPadding};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.body};
  transition: 0.3s ease-in-out all;
  box-shadow: ${({ theme }) => `0 1px 8px 2px ${theme.colors.icons}22`};
  &:hover {
    transition: 0.3s ease-in-out all;
    box-shadow: ${({ theme }) => `0 1px 8px 2px ${theme.colors.icons}77`};
  }
  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}px) {
    width: 100%;
  }

  & ul {
    padding-inline-start: 0px;
    width: calc(100% - 80px);
    & li {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
